import {required} from '../rules'

export default {
  id: 'campus',
  component: 'fr-campus',
  meta: {
    submitKey: 'campus',
    default: null,
    label: 'Campus',
    placeholder: 'Select Campus',
    options: [],
    rules: [{...required}],
    classes: [],
    visibility: [],
    display: []    
  },
  admin: {
    name: 'Campus',
    mandatory: false,
    required: true,
    required_always: false,
    required_never: false,
    dynamic: false,
    dynamicDisplay: false,
    dynamicLabel: false,
    conditionalLogicNotComplete: false,
  }
}