import anticipatedHighSchoolGraduationYear from './anticipated-high-school-graduation-year'
import campus from './campus'
import city from './city'
import country from './country'
import email from './email'
import firstName from './first-name'
import formQualifierCheckbox from './form-qualifier-checkbox'
import formQualifierDropdown from './form-qualifier-dropdown'
import highestDegreeLevel from './highest-degree-level'
import intendedTermOfEntry from './intended-term-of-entry'
import intendedYearOfEntry from './intended-year-of-entry'
import lastName from './last-name'
import parentsEmail from './parents-email'
import parentsFirstName from './parents-first-name'
import parentsLastName from './parents-last-name'
import parentsPhone from './parents-phone'
import partnerships from './partnerships'
import phone from './phone'
import programQualifierCheckbox from './program-qualifier-checkbox'
import programQualifierDropdown from './program-qualifier-dropdown'
import programsFilter from './programs-filter'
import programs from './programs'
import prospectType from './prospect-type'
import smsConsentCheckbox from './sms-consent-checkbox'
import militaryAffiliatedCheckbox from './military-affiliated-checkbox'
import state from './state'
import street from './street'
import zipcode from './zipcode'

export default [
  highestDegreeLevel,
  programsFilter,
  programs,
  programQualifierCheckbox,
  programQualifierDropdown,
  firstName,
  lastName,
  email,
  phone,
  country,
  state,
  zipcode,
  formQualifierCheckbox,
  formQualifierDropdown,
  smsConsentCheckbox,
  militaryAffiliatedCheckbox,
  anticipatedHighSchoolGraduationYear,
  campus,
  city,
  intendedTermOfEntry,
  intendedYearOfEntry,
  parentsEmail,
  parentsFirstName,
  parentsLastName,
  parentsPhone,
  partnerships,
  prospectType,
  street
]