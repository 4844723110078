import fieldLibraries from '../field-libraries'
import { cloneDeep } from 'lodash'

//I'm going to try and inject the highest degree/affiliate field into the presets as a default
let libraries = cloneDeep(fieldLibraries);


for (let lib in libraries) {
  libraries[lib].forEach(field => {
    // Set highest degree as a required field.
    if (field.id === 'highest-degree-level') {
      field.admin.mandatory = true
    }
  });
}
export default libraries

